import styled from '@emotion/styled';

const ProjectMeta = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: .5rem 0;

  h4, h5 { width: 100%; }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 50%;
    }
  }

  @media only screen and (max-width : 700px) {
    ul {
      display: block;
    
      li {
        width: 100%;
      }
    }
  }

  .techLogos {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;

    img {
      max-width: 10%;
      max-height: 50px;
      filter: grayscale(1);
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */
    .techLogos,
    .techHeading { display: none; }
  }

  @media only screen and (max-width : 820px) {
    .techLogos {
      flex-wrap: wrap;
      justify-content: space-between;

      img {
        width: 20%;
        max-width: 100px;
        max-height: 50px;
      }
    }
  }

  @media only screen and (max-width : 620px) {
    .techLogos {
      justify-content: center;

      img {
        width: 30%;
        margin: 0 30px 30px;
      }
    }
  }

  @media only screen and (max-width : 420px) {
    .techLogos {
      img {
        width: 100px;
        margin: 0 15px 20px;
      }
    }
  }
`;

export default ProjectMeta;
