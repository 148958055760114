import styled from '@emotion/styled';

const ProjectWrapper = styled('section')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;

  ul {
    margin-bottom: 1rem;
  }

  .project {
    width: 70%;
  }

  .logo {
    width: 25%;
    padding: 1rem 0 2rem;

    &.south32 {
      max-height: 150px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
        /* IE10+ specific styles go here */
        height: 150px;
      }
    }
  }

  @media only screen and (max-width : 700px) {
    .project {
      width: 100%;
    }

    .logo {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
  }

  .mainImage {
    margin-bottom: 1rem;
    width: 100%;
    border-radius: 15px;
  }

  .solution {
    width: 100%;
    padding: .5rem 0;
  }

  .screens {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;

    div {
      width: 50%;
      background: transparent;

      &:nth-of-type(even) {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      &:nth-of-type(odd) {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }
`;

export default ProjectWrapper;
