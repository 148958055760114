import styled from '@emotion/styled';

const ProjectQuote = styled('blockquote')`
  font-size: 20pt;
  margin: 2rem 5rem 2rem;
  font-style: italic;
  border-left: 5px solid #efefef;
  padding-left: 15px;

  &:before {
    content: '“';
    font-size: 80pt;
    font-weight: 900;
    position: absolute;
    margin-left: -6rem;
    margin-top: -1.5rem;
  }

  .translation {
    display: block; 
    font-style: normal; 
    font-size: 70%; 
    padding-top: .5rem;
  }

  @media only screen and (max-width : 520px) {
    margin: 0rem 1rem 1rem 2rem;
    border-left: 5px solid #efefef;
    padding-left: 15px;

    &:before {
      font-size: 40pt;
      font-weight: 900;
      position: absolute;
      margin-left: -3.5rem;
      margin-top: -.5rem;
    }
  }

  @media only screen and (max-width : 420px) {
    font-size: 16pt;
  }
`;

export default ProjectQuote;
