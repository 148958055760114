import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoInformia.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoLexis from '../../assets/lexis-nexis.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const Informia = () => {
  const {desktopHome, ipadNews, mobileNews} = useStaticQuery(graphql`
    query {
      desktopHome: file(relativePath: { eq: "informia/desktopHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ipadNews: file(relativePath: { eq: "informia/ipadNews.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileNews: file(relativePath: { eq: "informia/mobileNews.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Providing a real-time media monitoring and business intelligence service</h2>
        <p>
          The increasingly fast pace of the business world means you need to know what's going on around you and what's
          relevant without being overwhelmed - and you need to know it when it happens.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>Informia required Talen to design and develop it's flagship product <strong>PaperBoy</strong> - a real-time media monitoring and business
          intelligence service that brings tailored industry news directly to you via a unique web application.</p>
          <p><strong>PaperBoy</strong> was built from the ground up using the latest web standards to ensure it delivers the best user experience
          on all browsers and devices.</p>

        </div>

        <img src={Logo} alt="Informia logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with Informia to develop a unique application that includes:</p>
          <ul>
            <li>Custom News Feed Integration</li>
            <li>Competitor Analysis</li>
            <li>User Collaboration</li>
            <li>Daily Alerts</li>
            <li>Search</li>
          </ul>

          <p>We also manage the day to day operations of the platform including:</p>
          <ul>
            <li>Cloud Hosting</li>
            <li>System Backups and Monitoring</li>
            <li>Performance Tuning and Optimisation</li>
            <li>Feature Enhancements</li>
            <li>Support and Maintenance</li>
            <li>Security Analysis and Review</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={desktopHome.sharp.fluid} fadeIn alt="Informia Home page" />
          <Img fluid={mobileNews.sharp.fluid} fadeIn alt="Informia News page" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <ul>
              <li>Web Application Development</li>
              <li>Systems Integration</li>
              <li>UX Design</li>
              <li>Responsive Design</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={ipadNews.sharp.fluid} fadeIn alt="" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft .Net logo" />
            <img src={LogoLexis} alt="Lexis Nexis logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          We work with the team at Talen almost daily on further developing PaperBoy and they are always one step
          ahead of the game...
        </ProjectQuote>

        <ProjectNav>
          <div className="prev">
            <img src={NavArrow} />
            <Link to="/work/south32">South32</Link>
          </div>
          <div className="next">
            <Link to="/work/weighIt">WeighIt</Link>
            <img src={NavArrow} />
          </div>
        </ProjectNav>
      </ProjectWrapper>
    </Layout>
  );
};

export default Informia;
