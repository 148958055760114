import styled from '@emotion/styled';

const ProjectNav = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;

  div {
    width: 50%;
    padding-top: 1rem;

    img {
      fill: #ccc;
      width: 45px;
      height: 45px;
    }

    a {
      font-size: 20pt;
      text-decoration: none;
      margin-right: 10px;
      position: relative;
      top: -4px;
      font-weight: bold;
      color: #555;
    }

    a:before {
      content: 'Next';
      font-size: 11px;
      position: absolute;
      top: -10px;
      color: #ccc;
      font-weight: normal;
      text-transform: uppercase;
    }

    &.prev {
      text-align: left;

      img {
        transform: rotate(180deg);
      }

      a {
        margin-left: 10px;

        &:before {
          content: 'Prev';
        }
      }
    }

    &.next {
      text-align: right;
    }

    @media only screen and (max-width : 400px) {
      img {
        width: 35px;
        height: 35px;
      }

      a {
        font-size: 16pt;
        top: -4px;
      }
    }
  }
`;

export default ProjectNav;
